import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="w-12 h-12 sm:w-16 sm:h-16">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#3B82F6', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#6366F1', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <path d="M30 20 L30 80 M70 20 L50 80 L70 80" stroke="url(#grad1)" strokeWidth="8" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20 60 Q40 40, 60 55 T100 35" stroke="url(#grad1)" strokeWidth="2" fill="none"/>
  </svg>
);

const Header = ({ currentPage, onNavigate }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const NavItem = ({ page, label }) => (
    <button 
      className={`text-lg hover:text-blue-600 transition-colors ${currentPage === page ? 'text-blue-600' : ''}`}
      onClick={() => {
        onNavigate(page);
        setMobileMenuOpen(false);
      }}
    >
      {label}
    </button>
  );

  return (
    <header className="flex flex-col sm:flex-row items-center justify-between mb-8 sm:mb-16">
      <div className="flex items-center justify-between w-full sm:w-auto">
        <div className="flex items-center space-x-4">
          <Logo />
          <h1 className="text-2xl sm:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
            Investing Vault
          </h1>
        </div>
        <button onClick={toggleMobileMenu} className="sm:hidden">
          {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      <nav className={`${mobileMenuOpen ? 'block' : 'hidden'} sm:block w-full sm:w-auto mt-4 sm:mt-0`}>
        <ul className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6 bg-white sm:bg-transparent p-4 sm:p-0 rounded shadow-md sm:shadow-none">
          <li><NavItem page="home" label="Home" /></li>
          <li><NavItem page="about" label="About" /></li>
          <li><NavItem page="contact" label="Contact" /></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;