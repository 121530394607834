import React from 'react';
import { motion } from 'framer-motion';
import { Code, Rocket, Users, Zap } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div 
    className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-xl p-6 shadow-lg border border-gray-200"
    whileHover={{ y: -5, boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' }}
  >
    <Icon className="h-8 w-8 text-blue-600 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const About = () => {
  return (
    <div>
      <motion.h1 
        className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        About Investing Vault
      </motion.h1>
      
      <motion.div 
        className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-xl p-8 shadow-lg mb-12 border border-gray-200"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <p className="mb-4">
          Welcome to Investing Vault! I'm Marc Smith, the solo builder behind this project. My passion lies in creating applications that offer immense value without the need for a large budget. In a world where many innovative tools are either hard to find or come with a hefty price tag, I aim to bridge that gap.
        </p>
        <h2 className="text-2xl font-semibold mb-2">The Motivation</h2>
        <p className="mb-4">
          The driving force behind Investing Vault is simple: build mini applications that make a big impact. Whether it's boosting productivity, harnessing the power of data, or simply helping you do things faster and better, my goal is to create tools that are both powerful and accessible.
        </p>
        <h2 className="text-2xl font-semibold mb-2">Who It's For</h2>
        <p className="mb-4">
          Investing Vault is tailored for small businesses and individuals. I understand the challenges of finding effective tools without breaking the bank. That's why every app I create is designed to provide immediate, tangible benefits.
        </p>
        <h2 className="text-2xl font-semibold mb-2">Our Approach</h2>
        <p>
          At Investing Vault, it's all about shipping quickly and iterating fast. I believe in the power of constant improvement, and I'm committed to delivering innovative solutions with each release. This approach allows me to stay agile, adapt to feedback, and continually enhance the functionality of the applications I build.
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <FeatureCard 
          icon={Rocket}
          title="Rapid Development"
          description="Quick shipping and fast iterations to bring you the latest innovations."
        />
        <FeatureCard 
          icon={Users}
          title="User-Centric"
          description="Designed for small businesses and individuals seeking powerful, affordable tools."
        />
        <FeatureCard 
          icon={Code}
          title="Solo Built"
          description="Crafted with passion by a single developer dedicated to quality and value."
        />
        <FeatureCard 
          icon={Zap}
          title="Immediate Impact"
          description="Tools designed to provide tangible benefits from day one."
        />
      </div>
    </div>
  );
};

export default About;